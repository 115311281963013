@import '../../../assets/stylesheets/_utils';

.paddingTop {
  padding-top: 64px;
}

.mainMaxWidth {
  max-width: 1164px;
}

.main {
  min-height: 75vh;
  position: relative;
  margin: 0 auto;
  padding: 0 32px;

  display: flex;

  /* SM under */
  @media screen and (max-width: $screen-sm-max) {
    display: block;
  }

  > nav {
    width: 200px;
    border-right: 1px solid $colorStrokeContent;
    margin-right: $p2;

    /* SM under */
    @media screen and (max-width: $screen-sm-max) {
      display: block;
      width: inherit;
      border-right: none;
      margin-bottom: $p2;
      margin-right: 0;
      border-left: 2px solid $colorStrokeContent;
    }

    .section {
      margin-bottom: $p2;

      &:last-child {
        margin-bottom: 0;
      }

      .list {
        display: block;
        position: relative;
        font-size: 14px;

        .link {
          &.active {
            border-right: 1px solid $colorFillLinkText;
            margin-right: -1px;

            /* SM under */
            @media screen and (max-width: $screen-sm-max) {
              border-right: none;
              margin-right: 0;
              border-left: 2px solid $colorFillLinkText;
              margin-left: -2px;
            }
          }
        }

        a {
          text-decoration: none;
          padding: 15px 10px;
          display: block;

          /* SM under */
          @media screen and (max-width: $screen-sm-max) {
            padding: 4px 10px;
          }
        }
        .label {

        }
        .icon {
          position: absolute;
          left: -28px;
          top: 50%;
          transform: translateY(-50%);
          height: 24px;

          /* SM under */
          @media screen and (max-width: $screen-sm-max) {
            display: none;
          }

          img {
            display: block;
            line-height: 1;
          }
        }
      }
    }
  }
  > main {
    flex: 1;
    max-width: 100%;
  }

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    padding-left: $p;
    padding-right: $p;
  }

  /* XS iOS Safari */
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }

  p {
    margin-bottom: $p;
    @include fontMedium;
  }
  img {
    max-width: 100%;
    // width: inherit;
  }
}
